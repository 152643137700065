<!--
	This is the user profile page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>

		<!-- Header Background Image -->
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg')"></div>
		<!-- / Header Background Image -->

		<!-- User Profile Card -->
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{padding: 0,}">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" src="images/robert.jpg" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">Robert Schoen</h4>
							<p>CEO / Co-Founder</p>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<p>Pro Plan</p>
					</a-col>
				</a-row>
			</template>
		</a-card>
		<!-- User Profile Card -->

		<a-row type="flex" :gutter="24">

			<!-- Platform Settings Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Platform Settings Card -->
				<CardPlatformSettings></CardPlatformSettings>
				<!-- / Platform Settings Card -->

			</a-col>
			<!-- / Platform Settings Column -->

			<!-- Profile Information Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Profile Information Card -->
				<CardProfileInformation></CardProfileInformation>
				<!-- / Profile Information Card -->

			</a-col>
			<!-- / Profile Information Column -->

			<!-- Conversations Column -->
			<a-col :span="24" :md="8" class="mb-24">

				<!-- Conversations Card -->
				<CardConversations
					:data="conversationsData"
				></CardConversations>
				<!-- / Conversations Card -->

			</a-col>
			<!-- / Conversations Column -->

		</a-row>

		<!-- Projects Card -->
		<a-card :bordered="false" class="header-solid h-full mb-24" :bodyStyle="{paddingTop: '14px'}">
			<template #title>
				<h6 class="font-semibold">Projects</h6>
				<p>Architects design houses</p>
			</template>

			<a-row type="flex" :gutter="[24,24]" align="stretch">

				<!-- Project Column -->
				<a-col :span="24" :md="12" :xl="6" v-for="(project, index) in projects" :key="index">
					<CardProject
						:id="project.id"
						:title="project.title"
						:content="project.content"
						:cover="project.cover"

					></CardProject>
				</a-col>
				<!-- / Project Column -->

				<!-- Project Column -->
				<a-col :span="24" :md="12" :xl="6">

					<!-- Project Upload Component -->
					<a-upload
						name="avatar"
						list-type="picture-card"
						class="projects-uploader"
						:show-upload-list="false"
					>
						<img v-if="false" src="" alt="avatar" />
						<div v-else>
							<a-icon v-if="false" type="loading" />
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
              </svg>

							<div class="ant-upload-text font-semibold text-dark">
								Create New Project
							</div>
						</div>
					</a-upload>
					<!-- / Project Upload Component -->

				</a-col>
				<!-- / Project Column -->

			</a-row>
		</a-card>
		<!-- / Projects Card -->

	</div>
</template>

<script>

	import CardPlatformSettings from "../components/Cards/CardPlatformSettings"
	import CardProfileInformation from "../components/Cards/CardProfileInformation"
	import CardConversations from "../components/Cards/CardConversations"
	import CardProject from "../components/Cards/CardProject"

	// Conversation's list data.
	const conversationsData = [
		{
			id: "1",
			title: "Ewout",
			code: "Hey Robert, de API...",
			avatar: "images/face-6.jpeg",
		},

	] ;

	// Project cards data
	const projects = [
		{
			id: 1,
			title: "Modern",
			content: "As Uber works through a huge amount of internal management turmoil.",
			cover: "images/home-decor-3.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
		{
			id: 2,
			title: "Scandinavian",
			content: "Music is something that every person has his or her own specific opinion about.",
			cover: "images/home-decor-2.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
		{
			id: 3,
			title: "Minimalist",
			content: "Different people have different taste, and various types of music, Zimbali Resort.",
			cover: "images/home-decor-1.jpeg",
			team: [
				"images/face-1.jpg",
				"images/face-4.jpg",
				"images/face-2.jpg",
				"images/face-3.jpg",
			],
		},
	] ;

	export default ({
		components: {
			CardPlatformSettings,
			CardProfileInformation,
			CardConversations,
			CardProject,
		},
		data() {
			return {
				// Active button for the "User Profile" card's radio button group.
				profileHeaderBtns: 'overview',

				// Associating Conversation's list data with its corresponding property.
				conversationsData,

				// Project cards data
				projects,
			}
		},
	})

</script>

<style lang="scss">
</style>