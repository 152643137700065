<template>

  <!-- Platform Settings Card -->
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: 0 }">
    <template #title>
      <h6 class="font-semibold m-0">Platform Settings</h6>
    </template>
    <ul class="list settings-list">
      <li>
        <h6 class="list-header text-sm text-muted">CONNECTIONS</h6>
      </li>
      <li>
        <a-switch v-model="notifyForConnectionStatus"/>
        <span>Notify me for connection status updates</span>
      </li>
      <li>
        <a-switch v-model="notifyForNewConnection"/>
        <span>Notify me when a connection is created or updated</span>
      </li>
      <li>
        <a-switch v-model="notifyForMaximumCalls"/>
        <span>Notify me when the maximum calls is reached</span>
      </li>
      <li>
        <h6 class="list-header text-sm text-muted m-0">GENERAL</h6>
      </li>
      <li>
        <a-switch v-model="newProjects"/>
        <span>New projects</span>
      </li>
      <li>
        <a-switch v-model="monthlyStatusReport"/>
        <span>Monthly status report</span>
      </li>
      <li>
        <a-switch v-model="developerMode"/>
        <span>Developer mode</span>
        <Transition>
          <small v-if="!developerMode"> (You are currently using <b style="color: #188ffd;">simplified mode</b>)</small>
        </Transition>
      </li>


    </ul>
  </a-card>
  <!-- / Platform Settings Card -->

</template>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter,
.v-leave-to {
  opacity: 0;
}

</style>

<script>

export default ({
  data() {
    return {
      // Binded model property for "Platform Settings" switch button for "Email ... follows me" .
      notifyForConnectionStatus: true,

      // Binded model property for "Platform Settings" switch button for "Email ... answers me" .
      notifyForNewConnection: false,

      // Binded model property for "Platform Settings" switch button for "Email ... mentions me" .
      notifyForMaximumCalls: true,

      // Binded model property for "Platform Settings" switch button for "New launches and projects" .
      newProjects: false,

      // Binded model property for "Platform Settings" switch button for "Monthly product updates" .
      monthlyStatusReport: false,

      // Binded model property for "Platform Settings" switch button for "Subscribe to newsletter" .
      developerMode: false,
    }
  },
})

</script>